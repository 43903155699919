<template>
	<div class="add-discount">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div style="padding: 15px;background: #fff;">
				<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;margin-bottom: 15px;color: #303133;">基本信息</div>

				<el-form-item label="组合套餐名称" label-width="120px" prop="activeName">
					<el-input :disabled="onlyLook" v-model="ruleForm.activeName" placeholder="请输入组合套餐名称" maxlength="20" style="width:200px"></el-input>
				</el-form-item>

				<el-form-item label="活动时间" label-width="120px" prop="activityTime">
					<el-date-picker :disabled="onlyLook" v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
					range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
				<el-form-item label="选择商品" label-width="120px" required>
					<div style="align-items: center;">
						<el-button type="primary" @click="choseActive">选择商品</el-button>
						<span style="margin-left: 20px;color: red;">组合套餐商品不能少于两种不能多于六种</span>
					</div>
				</el-form-item>
			</div>

			<div style="background: #fff;padding: 0 15px 30px 15px;margin-bottom: 10px;">
				<div class="table-container" style="min-height: 30px;margin-bottom: 50px;">
					<el-table :data="ruleForm.selectedList" style="width: 100%;" ref="productChose">
						<el-table-column label="商品" width="300">
							<template slot-scope="scope">
								<div class="product-info">
									<!-- <img v-if="scope.row.thumbImgUrl" :src="imgUrl + scope.row.thumbImgUrl" /> -->
									<!-- <svg-icon v-else icon-class="noImgIcon" /> -->
									<div>
										<div>
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
										</div>
										<span v-if="scope.row.totalStock<1" style="color: #E51C23;">已售罄</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="originalPrice" label="划线价"></el-table-column> -->
						<el-table-column prop="salePrice" label="销售价"></el-table-column>
						<el-table-column label="活动价" width="200px">
							<template slot-scope="scope">
								<div class="discount-item">
									<el-form-item inline-message :prop="'selectedList.' +scope.$index + '.DiscountPrice'" :rules="rules.DiscountPrice"
									style="display:inline-block;margin:20px 0;height:50px;" label-width="0">
										<el-input v-model="scope.row.DiscountPrice" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
										:disabled="onlyLook" size="mini" class="input-with-select" style="width: 100px;margin: 0 5px;"
										@change.native="changeDiscountPrice(scope.row)"></el-input>
									</el-form-item>
									<el-tooltip placement="top" effect="light">
										<div slot="content">
											<el-table :data="scope.row.pusherProfitConfigs">
												<el-table-column prop="pusherLevelValue" label="级别" width="150px"></el-table-column>
												<el-table-column prop="selfSaleProfitDiscount" label="预计自营销售收益" width="150px"></el-table-column>
												<el-table-column prop="pushSaleProfitDiscount" label="预计直推销售收益" width="150px"></el-table-column>
											</el-table>
										</div>
										<i class="el-icon-question" style="margin-left: 5px;"></i>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="折扣" width="200px">
							<template slot-scope="scope">
								<div class="discount-item">
									<span style="margin-right: 5px;">打</span>
									<el-form-item inline-message :prop="'selectedList.' +scope.$index + '.Discount'" :rules="rules.Discount" style="display:inline-block;margin:20px 0;height:50px;"
									label-width="0">
										<el-input v-model="scope.row.Discount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,1})/g)[0]||'';}).call(this);"
										:disabled="onlyLook" size="mini" class="input-with-select" style="width: 100px;margin: 0 5px;"
										@change.native="changeDiscount(scope.row)"></el-input>
									</el-form-item>
									<span style="margin-left: 5px;">折</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="totalStock" label="库存"></el-table-column>
						<el-table-column label="操作" width="200" v-if="!onlyLook">
							<template slot-scope="scope">
								<span style="color:#C0C4CC;" v-if="onlyLook">删除</span>
								<span style="color:#F56C6C;cursor:pointer;" v-else @click="deleteChose(scope.row)">删除</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<el-form-item label="统一折扣" inline-message label-width="120px" prop="allDiscount">
					<el-input :disabled="onlyLook" @change="changeCommonDiscount()" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,1})/g)[0]||'';}).call(this);"
					v-model="ruleForm.allDiscount" placeholder="请填写统一折扣" maxlength="20" style="width:200px;margin-right: 10px;"></el-input>
					<el-tooltip class="item" effect="dark" content="平均折扣" placement="top">
						<i class="el-icon-question"></i>
					</el-tooltip>
				</el-form-item>
				<el-form-item label="组合套餐价" label-width="120px" prop="allDiscountPrice">
					<el-input :disabled="true" v-model="allDiscountPrice" maxlength="20" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item label="节省" label-width="120px" prop="jieshengPrice">
					<el-input :disabled="true" v-model="jieshengPrice" maxlength="20" style="width:200px"></el-input>
				</el-form-item>
				<el-form-item label="是否上架" label-width="120px" prop="jieshengPrice">
					<el-switch :disabled="onlyLook" v-model="isActiveOn"></el-switch>
				</el-form-item>
			</div>
		</el-form>
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1400px" class="dialog">
			<select-produce api="activitycouponChoseproductlist" :params="['goodsName', 'barCode']" :selectedData="ruleForm.selectedList"
			@getSelectList="getSelectList" :isCompose='true' :visible.sync="addProductDialogShow" v-if="addProductDialogShow"></select-produce>
		</el-dialog>
		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 20px;display: flex;justify-content: center;">
			<el-button style="width: 150px;" @click="handleCancel">取消</el-button>
			<el-button style="width: 150px;margin-left: 40px;" type="primary" v-if="!onlyLook" @click="saveBtn" :loading="loading">保存</el-button>
		</div>

	</div>
</template>

<script>
	import {
		comboAdd,
		comboDetail,
		comboUpdate,
		comboGoodsDelete,
	} from '@/api/wyUsedInterface.js'
	import selectProduce from '../coupon/selectMultiPro.vue';
	import config from '../../../config/index.js'
	export default {
		components: {
			selectProduce
		},
		data() {
			var checkDiscount = (rule, value, callback) => {
				let index1 = rule.fullField.split(".")[1];
				let index = Number(index1)
				let value1 = this.ruleForm.selectedList[index].Discount

				if (Number(value1) >= 10 || Number(value1) < 0) {
					return callback(new Error('0≦折扣≦9.9，最多1位小数'));
				}
				// else if (value1 == '' || value1 == null) {
				// 	return callback(new Error('0≦折扣≦9.99，最多2位小数'));
				// } 
				else {
					return callback()
				}
			};
			var checkDiscountPrice = (rule, value, callback) => {
				let index1 = rule.fullField.split(".")[1];
				let index = Number(index1)
				let priceMin = this.ruleForm.selectedList[index].salePrice
				let value1 = this.ruleForm.selectedList[index].DiscountPrice
				if (value1 > priceMin || value1 == priceMin) {
					return callback(new Error('活动价不可超过商品售价'));
				}
				// else if (value1 == '' || value1 == null) {
				// 	return callback(new Error('活动价填写错误，且不能为小数！'));
				// } 
				else {
					return callback()
				}

			};
			var checkCommonDiscount = (rule, value, callback) => {

				if (Number(value) >=10 || Number(value) < 0) {
					return callback(new Error('0≦折扣≦9.9，最多1位小数'));
				} 
				// else if (Number(value) < 0 || value == '' || value == null) {
				// 	return callback(new Error('0≦折扣≦9.9，最多1位小数'));
				// } 
				else {
					return callback()
				}
			};
			return {
				loading: false,
				ruleForm: {
					activeName: '',
					Discount: '',
					DiscountPrice: '',
					selectedList: [],
					activityTime: '',
					allDiscount: '', //统一折扣
				},
				rules: {
					Discount: [{
						required: true,
						trigger: 'change',
						validator: checkDiscount
					}],
					DiscountPrice: [{
						required: true,
						trigger: 'change',
						validator: checkDiscountPrice
					}],
					activeName: [{
							required: true,
							trigger: 'blur',
							message: '请输入组合套餐名称',
						},
						{
							min: 1,
							max: 20,
							message: '最多输入20个字',
							trigger: 'blur'
						}
					],
					allDiscount: [{
						required: true,
						trigger: 'blur',
						validator: checkCommonDiscount
					}, ],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
				},
				allDiscountPrice: '0.00', //总套餐价格
				jieshengPrice: '0.00', //节省金额
				installationLength: 0,
				// selectedList: [],
				page: {
					total: 0,
					size: 10,
					current: 1
				},
				addProductDialogShow: false,
				currentPage1: 1,
				pageSize1: 5,
				Total1: 0,
				activityId: 0, //活动id
				onlyLook: 0,
				imgUrl: config.IMG_BASE,
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				isActiveOn: false,
				goodsList: [],
				level: ["会员卡", "会员卡", "会员卡", "铂金卡", "钻石卡", "黑金卡"],
			};
		},
		computed: {},
		beforeMount() {
			this.onlyLook = this.$route.query.type == '2' ? true : false;
			if (this.$route.query.id) {
				this.activityId = this.$route.query.id;
				this.getData();
			}
		},
		methods: {

			async getData() {
				this.loading = true
				const res = await comboDetail({
					comboId: +this.activityId
				})

				if (res.success) {
					const obj = res.data;
					this.ruleForm = {
						activeName: obj.comboName,
						Discount: '',
						DiscountPrice: '',
						activityTime: [obj.startTime, obj.endTime],
						allDiscount: (parseFloat(obj.avgDiscount) * 10).toFixed(1), //统一折扣
					}
					if (res.data.goodsList && res.data.goodsList.length) {
						this.goodsList = res.data.goodsList;
						res.data.goodsList.map(t => {
							t.Discount = (t.discount*10).toFixed(1);
							t.DiscountPrice = t.activityPrice;
							t.id = t.goodsId;
							var list = [];
							t.pusherProfitConfigs.map(item => {
								item.pusherLevelValue = this.level[item.pusherLevel - 1];
								item.pushSaleProfitDiscount = (item.pushSaleProfit * t.discount).toFixed(3);
								item.selfSaleProfitDiscount = (item.selfSaleProfit * t.discount).toFixed(3);
								if (item.pusherLevel > 2) {
									list.push(item);
								}
								return item;
							})
							t.pusherProfitConfigs = list;
							return t;
						})
						this.ruleForm.selectedList = res.data.goodsList.map(t => {
							return {
								...t,
								Discount: (parseFloat(t.discount) * 10).toFixed(1) + '',
								DiscountPrice: t.activityPrice,
								thumbImgUrl: t.goodsUrl,
								id: t.goodsId
							}

						});
						this.allDiscountPrice = res.data.comboPrice.toFixed(2);
						this.jieshengPrice = res.data.savePrice.toFixed(2);
						this.isActiveOn = res.data.shelfStatus == -1 ? false : true;
					}
				} else {
					this.$message({
						type: 'error',
						showClose: true,
						message: res.alertMsg
					})
				}
				this.loading = false

			},
			//设置打折
			changeDiscount(value) {
				console.log("---------",value);
				let Id = value.id
				if(value.Discount && value.Discount.indexOf('.')==0){
					value.Discount = '0'+value.Discount;
				}
				// if (Number(value.Discount) > 9.99) {
				// 	value.Discount = null
				// } else 
				if (value.Discount && Number(value.Discount) < 0) {
					value.Discount = null
				}
				if (Number(value.Discount) >= 0 && Number(value.Discount) <10) {
					if(value.Discount&&value.Discount.indexOf('.')>0){
						var arr = value.Discount.split('.');
						var str = '';
						if(arr[1].length>1){
							str = arr[1].substr(0,1);
							value.Discount = arr[0]+'.'+str;
						}
					}
					this.ruleForm.selectedList.forEach(t => {
						if (t.id == Id) {
							t.Discount = Number(value.Discount);
							let result = this.computedThreeDiscounts(1, t, Number(value.Discount));
							t.DiscountPrice = result.DiscountPrice;
							var list = [];
							t.pusherProfitConfigs.map(item => {
								item.pusherLevelValue = this.level[item.pusherLevel - 1];
								item.pushSaleProfitDiscount = (item.pushSaleProfit * result.Discount / 10).toFixed(3);
								item.selfSaleProfitDiscount = (item.selfSaleProfit * result.Discount / 10).toFixed(3);
								if (item.pusherLevel > 2) {
									list.push(item);
								}
								return item;
							})
							t.pusherProfitConfigs = list;
						}
					})
				} else {
					this.ruleForm.selectedList.forEach(t => {
						if (t.id == Id) {
							t.Discount = null;
							t.DiscountPrice = '';
						}
					})
				}

				this.$forceUpdate()
				this.$refs['ruleForm'].validateField('Discount');
				var discountTotal = 0;
				var totalDiscountMoney = 0;
				var totalMoney = 0;
				var isAll = true;
				if(value.Discount){
					this.ruleForm.selectedList.map(item => {
						if (Number(item.Discount) < 0) {
							isAll = false;
						}
						discountTotal = discountTotal + parseFloat(item.Discount);
						totalDiscountMoney = totalDiscountMoney + parseFloat(item.DiscountPrice);
						totalMoney = totalMoney + parseFloat(item.salePrice);
						return item;
					})
					if (isAll) {
						this.ruleForm.allDiscount = (discountTotal / this.ruleForm.selectedList.length).toFixed(1);
						this.allDiscountPrice = totalDiscountMoney.toFixed(2);
						this.jieshengPrice = (totalMoney - totalDiscountMoney).toFixed(2);
					} else {
						this.ruleForm.allDiscount = '';
						this.allDiscountPrice = '0.00';
						this.jieshengPrice = '0.00';
					}
				}
			},
			//设置折扣价
			changeDiscountPrice(value) {

				let Id = value.id

				value.DiscountPrice = ((value.DiscountPrice).match(/^\d*(\.?\d{0,2})/g)[0]) || null

				this.ruleForm.selectedList.forEach(t => {
					if (t.id == Id) {
						t.DiscountPrice = Number(value.DiscountPrice)
						let result = this.computedThreeDiscounts(2, t, Number(value.DiscountPrice))
						t.Discount = result.Discount;
						var list = [];
						t.pusherProfitConfigs.map(item => {
							item.pusherLevelValue = this.level[item.pusherLevel - 1];
							item.pushSaleProfitDiscount = (item.pushSaleProfit * result.Discount / 10).toFixed(3);
							item.selfSaleProfitDiscount = (item.selfSaleProfit * result.Discount / 10).toFixed(3);
							if (item.pusherLevel > 2) {
								list.push(item);
							}
							return item;
						})
						t.pusherProfitConfigs = list;
					}
				})
				console.log("----Discount-----", this.ruleForm.selectedList);
				this.$forceUpdate();
				this.$refs['ruleForm'].validateField('SpecifiedPrice');
				var discountTotal = 0;
				var totalDiscountMoney = 0;
				var totalMoney = 0;
				var isAll = true;
				this.ruleForm.selectedList.map(item => {
					if (item.Discount == '') {
						isAll = false;
					}
					discountTotal = discountTotal + parseFloat(item.Discount);
					totalDiscountMoney = totalDiscountMoney + parseFloat(item.DiscountPrice);
					totalMoney = totalMoney + parseFloat(item.salePrice);
					return item;
				})

				if (isAll) {
					this.ruleForm.allDiscount = (discountTotal / this.ruleForm.selectedList.length).toFixed(1);
					this.allDiscountPrice = totalDiscountMoney.toFixed(2);
					this.jieshengPrice = (totalMoney - totalDiscountMoney).toFixed(2);
				} else {
					this.ruleForm.allDiscount = '';
					this.allDiscountPrice = '0.00';
					this.jieshengPrice = '0.00';
				}
			},

			computedThreeDiscounts(type, item, settings) {
				let discount = 0
				let discountMin = 0
				let discountPrice = 0
				let discountPriceMin = 0
				if (type == 1) {
					// 设置的值是打折
					discount = settings
					discountPriceMin = (settings * item.salePrice / 10).toFixed(2)
					discountPrice = discountPriceMin
				} else if (type == 2) {
					// 设置的值是优惠价
					discountPrice = settings
					discountMin = item.salePrice == 0 ? 0 : (settings / item.salePrice * 10).toFixed(1)
					discount = discountMin
				}


				let result = {
					Discount: discount,
					DiscountPrice: discountPrice,
				}
				return result
			},
			choseActive() {
				if (this.onlyLook) {
					return;
				}
				this.addProductDialogShow = true;
			},
			changeCommonDiscount() {
				console.log("--allDiscount----",this.ruleForm.allDiscount)
				if(this.ruleForm.allDiscount&&this.ruleForm.allDiscount.indexOf('.')==0){
					this.ruleForm.allDiscount = '0'+this.ruleForm.allDiscount;
				}
				// if (Number(this.ruleForm.allDiscount) > 9.99) {
				// 	this.ruleForm.allDiscount = null
				// } else 
				if (Number(this.ruleForm.allDiscount) < 0) {
					this.ruleForm.allDiscount = null
				}

				if (Number(this.ruleForm.allDiscount) >= 0 && Number(this.ruleForm.allDiscount) <10) {
					if(this.ruleForm.allDiscount&&this.ruleForm.allDiscount.indexOf('.')>0){
						var arr = this.ruleForm.allDiscount.split('.');
						var str = '';
						if(arr[1].length>1){
							str = arr[1].substr(0,1);
							this.ruleForm.allDiscount = arr[0]+'.'+str;
						}
					}
					this.ruleForm.selectedList.forEach(item => {
						item.Discount = this.ruleForm.allDiscount;
						item.DiscountPrice = (parseFloat(item.salePrice) * this.ruleForm.allDiscount / 10).toFixed(2);
					})
				} else {
					this.ruleForm.allDiscount = null
				}

				this.$forceUpdate()
				this.$refs['ruleForm'].validateField('Discount');
				var totalDiscountMoney = 0;
				var totalMoney = 0;
				this.ruleForm.selectedList.map(item => {
					totalDiscountMoney = totalDiscountMoney + parseFloat(item.DiscountPrice);
					totalMoney = totalMoney + parseFloat(item.salePrice);
					return item;
				})
				this.allDiscountPrice = totalDiscountMoney.toFixed(2);
				this.jieshengPrice = (totalMoney - totalDiscountMoney).toFixed(2);

			},
			getSelectList(data) {
				console.log("---getSelectList----", data)
				if (data.length > 6) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多选择六个商品！'
					})
					return;
				}

				this.addProductDialogShow = false
				if (this.ruleForm.selectedList.length) {
					let temp = this.ruleForm.selectedList.map(item => item.id)
					let resArr = data.filter(item => !temp.includes(item.id))
					resArr = resArr.map(t => {
						return {
							...t,
							Discount: '',
							DiscountPrice: '',
							originalPrice: t.originalPrice,
						}
					})
					resArr.forEach(t => {
						this.ruleForm.selectedList.push(t)
					})
				} else {
					var list = data.map(t => {
						return {
							...t,
							Discount: '',
							DiscountPrice: '',
							originalPrice: t.originalPrice,
						}
					})
					this.ruleForm.selectedList = list;
				}
				if (this.ruleForm.allDiscount) {
					this.ruleForm.selectedList.forEach(item => {
						item.Discount = this.ruleForm.allDiscount;
						item.DiscountPrice = (parseFloat(item.salePrice) * this.ruleForm.allDiscount / 10).toFixed(2);
					})

					this.$forceUpdate()
					this.$refs['ruleForm'].validateField('Discount');
					var totalDiscountMoney = 0;
					var totalMoney = 0;
					this.ruleForm.selectedList.map(item => {
						totalDiscountMoney = totalDiscountMoney + parseFloat(item.DiscountPrice);
						totalMoney = totalMoney + parseFloat(item.salePrice);
						return item;
					})
					this.allDiscountPrice = totalDiscountMoney.toFixed(2);
					this.jieshengPrice = (totalMoney - totalDiscountMoney).toFixed(2);
				}
				this.ruleForm.selectedList.map(item => {
					var list = [];
					item.pusherProfitConfigs.map(t => {
						t.pusherLevelValue = this.level[t.pusherLevel - 1];
						t.pushSaleProfitDiscount = t.pushSaleProfit;
						t.selfSaleProfitDiscount = t.selfSaleProfit;
						if (t.pusherLevel > 2) {
							list.push(t);
						}
						return t;
					})
					item.pusherProfitConfigs = list;
					return item;
				})
				console.log("---selectedList----", this.ruleForm.selectedList)
			},
			deleteChose(val) {
				this.$alert('确定删除当前商品吗', '温馨提示', {
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: action => {
						var isHave = false;
						if (this.activityId && this.goodsList.length) {
							this.goodsList.map(item => {
								if (val.id == item.id) {
									isHave = true;
								}
								return item;
							})
						}
						if (action == 'confirm') {
							if (isHave) {
								this.deleteGoods(val.id);
							} else {
								this.ruleForm.selectedList = this.ruleForm.selectedList.filter(t => {
									if (t.id != val.id) {
										return t
									}
								})
								this.$forceUpdate()
								this.$message({
									showClose: true,
									type: 'success',
									message: '删除成功'
								})
							}
						}
					}
				});
			},

			async deleteGoods(id) {
				let res = await comboGoodsDelete({
					comboId: +this.activityId,
					goodsId: id
				});
				if (res.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '删除成功'
					})
					this.ruleForm.selectedList = this.ruleForm.selectedList.filter(t => {
						if (t.id != id) {
							return t
						}
					})
					this.$forceUpdate()
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: '删除失败'
					})
				}
			},

			handleCancel() {
				this.$router.back();
			},

			saveBtn() {

				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.ruleForm.selectedList.map(item => {
							if (!item.Discount || item.Discount < 0 || item.Discount > 9.9) {
								this.installationLength++;
							}
							return item;
						})
						if (this.installationLength) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '商品折扣信息配置错误'
							})
							console.log("--------",this.ruleForm.selectedList)
							return
						}
						if (!this.ruleForm.selectedList.length) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请选择活动商品'
							});
							return;
						}
						if (this.ruleForm.selectedList.length < 2) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '至少选择2个商品'
							});
							return;
						}
						console.log("-----success-----")
						this.sureSave();
					} else {
						console.log('error submit!!');
						//this.$message.error('请完善活动信息');
						return false;
					}
				});

			},
			async sureSave() {
				this.loading = true
				try {
					let TimeLimitDiscountProductList = this.ruleForm.selectedList.map(t => {
						return {
							goodsId: t.id,
							activityPrice: +t.DiscountPrice,
							discount: t.Discount,
						}
					})
					const params = {
						comboName: this.ruleForm.activeName,
						startTime: this.ruleForm.activityTime[0],
						endTime: this.ruleForm.activityTime[1],
						goodsList: TimeLimitDiscountProductList,
						shelfStatus: this.isActiveOn ? 1 : -1, //上架状态 -1 下架 1 上架
					}
					console.log("---params---", params);
					let res = null;

					if (this.activityId > 0) {
						params.id = this.activityId;
						res = await comboUpdate(params);
					} else {
						res = await comboAdd(params);
					}
					if (res.success) {
						this.$message({
							type: 'success',
							showClose: true,
							message: this.activityId > 0 ? '编辑成功' : '添加成功'
						})
						this.$router.push({
							path: '/market/combinedGoods/index'
						})
					} else {
						this.$message({
							type: 'error',
							showClose: true,
							message: res.alertMsg
						})
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
		},

	}
</script>

<style lang="less" scoped>
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.discount-container {
		.discount-item {
			display: inline-block;
		}
	}

	.discount-item {
		/deep/.el-input__inner {
			color: #909399 !important;
		}
	}

	.activepromotionMethod {
		/deep/.el-input__inner {
			color: #333 !important;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}
</style>
